import request from '@/api/request'
import qs from 'qs'

// 登录方法
export function login(username, password, code, uuid, loginType) {
  const data = {
    username,
    password,
    code,
    uuid,
    loginType
  }
  return request({
    url: 'auth/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 手机号登录
export function loginPhone(data) {
  return request({
    url: '/student/login/codeLogin',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/system/user/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout(data) {
  return request({
    url: '/auth/logout',
    method: 'delete'
    // data: data,
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/code',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

// 根据账号获取机构
export function selectTenantPage(data) {
  return request({
    url: '/student/sysUserTenant/selectTenantPage',
    method: 'post',
    data: qs.stringify(data),
    headers: { 'content-type': 'application/x-www-form-urlencoded' }
  })
}

// 根据机构id判断机构是否停用 // 根据机构id，返回机构详情信息
export function selectTenantInfo(data) {
  return request({
    url: '/kaoshiTenant/tenant/selectTenantInfo?tenantId=' + data,
    method: 'get'
  })
}

// 根据机构id和用户账号（身份证）获取学生信息
export function getStuInfoByIT(data) {
  return request({
    url: '/student/student/getStuInfoByIT',
    method: 'post',
    data: data
  })
}

// 获取手机号验证码
export function getPhoneCode(data) {
  return request({
    url: '/student/login/sendCode',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data,
    timeout: 20000
  })
}

// 效验当前登陆人院校编码是否正确 
export function selectTenantCodeInfo(data){
  return request({
    url: '/kaoshiTenant/tenant/selectTenantCodeInfo',
    headers: {
      isToken: false
    },
    method: 'get',
    params:data,
    timeout: 20000
  })
}
