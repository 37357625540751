<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-08-24 10:55:32
 * @LastEditors: 孙嘉欣
 * @LastEditTime: 2024-02-19 10:37:46
 * @FilePath: \stu_exam\src\components\layout\mainView.vue
-->
<template>
  <!-- 蓝色主题 -->
  <div
    class="avue-contail blue-theme">
    <!-- 白色主题 -->
    <!-- <div class="avue-contail"> -->
    <div class="avue-header">
      <!-- 顶部导航栏 -->
      <top />
    </div>
    <!-- 主题视图 -->
    <div class="avue-main"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import sidebar from '../sidebar/index.vue'
import top from '../top/index.vue'
export default {
  components: {
    sidebar,
    top
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>

