/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-12-06 14:52:50
 * @LastEditors: lidandan
 * @LastEditTime: 2023-11-02 16:10:25
 * @FilePath: \yzjn_student_pc\src\api\axios.js
 */

import axios from 'axios'
import { serialize } from '@/util'
import { getStore } from '@/util/store'
import NProgress from 'nprogress' // progress bar
import errorCode from '@/const/errorCode'
import { Message, MessageBox } from 'element-ui'
import 'nprogress/nprogress.css'
import qs from 'qs'
import store from '@/store' // progress bar style
axios.defaults.timeout = 30000
// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true
// NProgress Configuration
NProgress.configure({
  showSpinner: false
})

// HTTPrequest拦截
axios.interceptors.request.use(
  config => {
    NProgress.start()
    let TENANT_ID
    if (getStore({ name: 'tenantInfo' })) {
      TENANT_ID = getStore({ name: 'tenantInfo' })
    } else {
      TENANT_ID = ''
    }
    const isToken = (config.headers || {}).isToken === false
    const token = getStore({ name: 'token' })
    if (token && !isToken) {
      config.headers['token'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
      config.headers['Authorization'] = 'Bearer ' + token // token
    }

    if (TENANT_ID) {
      config.headers['TENANTIDS'] = TENANT_ID // 租户ID
    }
    config.headers['CLIENT-TOC'] = 'Y' // 租户ID
    // headers中配置serialize为true开启序列化
    if (config.method === 'post' && config.headers.serialize) {
      config.data = serialize(config.data)
      delete config.data.serialize
    }
    if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// HTTPresponse拦截
axios.interceptors.response.use(
  res => {
    NProgress.done()
    const status = Number(res.status) || 200
    const message = res.data.msg || errorCode[status] || errorCode['default']
    // 后台定义 424 针对令牌过去的特殊响应码
    if (status === 424) {
      MessageBox.confirm('令牌状态已过期，请点击重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          store.dispatch('LogOut').then(() => {
            // 刷新登录页面，避免多次弹框
            // this.$message.success('正在退出...')
            window.localStorage.clear()
            setTimeout(() => {
              // this.$router.push({ path: '/login' })
              window.location.href = '/yxbstudent/#/login?redirect=00'
            }, 2000)
          })
        })
        .catch(() => {})
      return
    }

    if (status !== 200) {
      Message({
        message: message,
        type: 'error'
      })
      return Promise.reject(new Error(message))
    }
    return res.data
  },
  error => {
    // 处理 503 网络异常
    if (error.response.status === 503) {
      Message({
        message: error.response.data.msg,
        type: 'error'
      })
    }
    NProgress.done()
    return Promise.reject(new Error(error))
  }
)
export default axios
