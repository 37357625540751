<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 王东润
 * @Date: 2023-05-10 18:05:44
 * @LastEditors: GL
 * @LastEditTime: 2023-12-21 10:15:59
 * @FilePath: \zyjn\src\components\upload\upload.vue
-->
<template>
  <div>
    <!--上传组件-->
    <el-upload ref="upload"
      :class="updata.upLoadType === 4 ? 'avatar-uploader' : 'upload-demo'"
      action="#"
      :drag="updata.upLoadType === 2"
      :multiple="updata.upLoadType !== 3 && updata.upLoadType !== 4&&!updata.duoXuan"
      :limit="updata.limit ? updata.limit : 5"
      :file-list="fileList"
      :auto-upload="false"
      :disabled="updata.disabled"
      :on-exceed="onExceed"
      :on-change="onChange">

      <el-button
        v-if="updata.upLoadType === 1"
        type="primary">点击上传</el-button>
      <i v-if="updata.upLoadType === 2"
        class="el-icon-upload"></i>
      <div
        v-if="updata.upLoadType === 2"
        class="el-upload__text">
        将文件拖到此处，或<em>点击上传</em>
      </div>
      <i v-if="updata.upLoadType === 3"
        class="el-icon-picture icon_picture"></i>
      <img
        v-if="updata.upLoadType === 4 && this.imgUrl"
        :src="this.imgUrl"
        class="avatar" />
      <i v-if="updata.upLoadType === 4 && !this.imgUrl"
        class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <!--展示列表-->
    <ul
      v-if="updata.upLoadType !== 3 && updata.upLoadType !== 4 && !updata.flag"
      class="upload_ul">
      <li
        v-for="(item, index) in fileList"
        :key="index"
        class="upload_li">
        <div
          class="upload_li_top">
          <div
            class="li_top_name">
            <i
              class="el-icon-document left_icon"></i>{{ item.name }}
          </div>
          <div>
            <i v-if="item.percentage === 100&&!updata.editFalse"
              class="el-icon-circle-check right_icon"></i>
            <i v-if="item.percentage === 100&&!updata.editFalse"
              class="el-icon-download center_icon"
              @click="downItem(index)"></i>
            <span
              v-else>{{ item.percentage }}%</span>
            <i v-if="item.percentage === 100 && !updata.disabled"
              class="el-icon-close close_icon"
              @click="delItem(item, index)"></i>
          </div>
        </div>
        <el-progress
          :percentage="item.percentage" />
      </li>
    </ul>
  </div>
</template>

<script>
import * as wangsu from 'wcs-js-sdk'
import { HTTP_URI } from '@/api/api'
import { wangSu } from '@/api/wangsu'
export default {
  props: {
    updata: {
      type: Object,
      default: () => {
        return {
          isInst: false,
          upLoadType: 1, // 样式类型
          size: 0, // 限制文件大小， 默认不限制
          sizeType: 'MB', // 限制文件大小， 默认不限制
          bizModel: 'general', // 模块
          flag: false,
          fileList: [], // 反显列表数据
          fileUrl: '', // 反显图片
          flag: false,
          fileTypes: [], // 校验文件类型， 默认所有
          disabled: false, // 禁用上传
          prohibit: false, // 禁用提示
          limit: 5, // 上传文件个数
          timeout: 0, // 超时毫秒数, 默认为0-不超时 超时错误可以重试上传
          concurrentRequestLimit: 5, // 并发数，谷歌最大6
          retryCount: 0, // 上传重试, 默认为0
          blockSize: 8 // 分片大小 单位M
        }
      }
    }
  },
  data() {
    return {
      fileList: [], // 上传文件列表展示数据
      fileUrl: '', // 图片路径
      imgUrl: '', // 反显路径
      replace: 0 // 是否覆盖
    }
  },
  computed: {
    // 反显列表数据
    propFileList() {
      return this.updata.fileList
    },
    // 反显图片数据
    propFileUrl() {
      return this.updata.fileUrl
    }
  },
  watch: {
    propFileList: {
      handler(val) {
        this.fileList = val || []
      },
      deep: true,
      immediate: true
    },
    propFileUrl: {
      handler(val) {
        this.imgUrl = val ? HTTP_URI.IMGURL + val : ''
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    // 上传接口
    onChange(file, fileList) {
      this.$emit('loading', true)
      this.fileList = fileList.map(item => {
        Object.assign(item, { iconType: false })
        return item
      })
      const File = file.raw // 上传文件
      const index = File.name.lastIndexOf('.')
      const suffix = File.name.slice(index + 1) // 后缀名
      // 筛选文件大小
      if (this.updata.size) {
        if (this.updata.sizeType == 'MB') {
          if (file.size / 1024 / 1024 > this.updata.size) {
            this.$message.error(`文件大小超过${this.updata.size}MB, 请重新上传`)
            setTimeout(() => {
              this.fileList.forEach((item, index, array) => {
                if (item.uid === file.uid) array.splice(index, 1)
              })
            }, 500)
            this.$emit('loading', false)
            return false
          }
        } else if (this.updata.sizeType == 'KB') {
          if (file.size / 1024 > this.updata.size) {
            this.$message.error(`文件大小超过${this.updata.size}KB, 请重新上传`)
            setTimeout(() => {
              this.fileList.forEach((item, index, array) => {
                if (item.uid === file.uid) array.splice(index, 1)
              })
            }, 500)
            this.$emit('loading', false)
            return false
          }
        }
      }
      // 筛选上传类型
      if (this.updata.fileTypes && this.updata.fileTypes.length) {
        if (!this.updata.fileTypes.some(item => item === suffix)) {
          this.$message.error('请上传符合条件的文件')
          setTimeout(() => {
            this.fileList.forEach((item, index, array) => {
              if (item.uid === file.uid) array.splice(index, 1)
            })
          }, 500)
          this.$emit('loading', false)
          return false
        }
      }
      // 请求token参数
      const tenantId = '1' // id 暂时写死
      const params = {
        tenantId,
        bizModel: this.updata.bizModel ? this.updata.bizModel : 'general',
        suffix
      }
      // 请求token
      wangSu(params, this.updata.isInst).then(res => {
        const { code, data } = res
        if (code === 200) {
          // 上传参数配置项
          const extraConfig = {
            timeout: this.updata.timeout ? this.updata.timeout : 0, // 超时毫秒数, 默认为0-不超时 超时错误可以重试上传
            concurrentRequestLimit: this.updata.concurrentRequestLimit ? this.updata.concurrentRequestLimit : 5, // 并发
            retryCount: this.updata.retryCount ? this.updata.retryCount : 0, // 上传重试, 默认为0
            blockSize: this.updata.blockSize ? this.updata.blockSize : 8 // 分片大小 单位M
          }
          const uploadObj = wangsu.wcsUpload(File, data.token, HTTP_URI.WSUPLOAD, extraConfig)
          const that = this
          // 开始上传
          uploadObj.putFile()
          // 上传中
          uploadObj.uploadProgress = function (progress) {
            that.fileList.forEach(item => {
              if (item.uid === file.uid) item.percentage = parseInt(progress.total.percent)
            })
          }
          // 错误回调
          uploadObj.onError = function (error) {
            console.log(error, '----')
            that.updata.prohibit || that.$message.error('上传文件失败')
            that.$emit('loading', false)
            setTimeout(() => {
              that.fileList.forEach((item, index, array) => {
                if (item.uid === file.uid) array.splice(index, 1)
              })
            }, 500)
          }
          // 成功回调
          uploadObj.onComplete = function (res) {
            file.status = 'success' // 更新文件状态
            that.updata.prohibit || that.$message.success('上传文件成功')
            that.fileUrl = data.fileUrl
            that.imgUrl = HTTP_URI.IMGURL + data.fileUrl // 反显图片路径
            // 单选不限制上传数量
            if (that.updata.upLoadType === 3 || that.updata.upLoadType === 4) that.fileList = []
            // 成功回调返回
            that.fileList.length && (that.fileList[that.fileList.length - 1].url = that.fileUrl)
            const upLoadParams = {
              fileUrl: that.fileUrl, // 存储路径
              fileList: that.fileList // 反显列表数据
            }
            that.$emit('successData', upLoadParams)
            that.$emit('loading', false)
          }
        } else {
          this.$message.error('获取token失败')
        }
      })
    },

    // 超出上传个数
    onExceed(files, fileList) {
      const num = this.updata.limit ? this.updata.limit : 5
      this.$message.error(`超出上传文件个数, 请选择${num}个以内`)
    },

    // 删除列表数据后返回列表
    delItem(item, index) {
      this.$confirm('是否确认删除名称为"' + item.name + '"的数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.fileList.splice(index, 1)
        this.$emit('delItem', this.fileList)
      })
    },

    // 下载选中数据
    downItem(index) {
      const Index = this.fileList[index].url.lastIndexOf('.')
      const suffix = this.fileList[index].url.slice(Index + 1) // 后缀名
      const url = HTTP_URI.IMGURL + this.fileList[index].url
      const link = document.createElement('a')
      const array = ['jpg', 'jpeg', 'png', 'pdf', 'JPG', 'JPEG', 'PNG', 'PDF']
      if (array.includes(suffix)) link.setAttribute('target', '_blank')
      link.setAttribute('href', url)
      document.body.appendChild(link)
      // 模拟手动点击a标签下载文件
      link.click()
      this.$message.success('下载文件成功')
      setTimeout(() => {
        // 删除DOM节点
        document.body.removeChild(link)
      }, 1000)
    },

    // 获取dom元素
    getUploadDom() {
      this.$refs.upload.$el.querySelector('input').click()
    }
  }
}
</script>

<style lang="scss" scoped>
.upload_ul {
  max-height: 300px;
  overflow-x: auto;
  width: 360px;
  padding-left: 0;
  .upload_li_top {
    display: flex;
    justify-content: space-between;
    .li_top_name {
      width: 280px;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }
    .left_icon {
      margin-right: 5px;
    }
    .right_icon {
      color: green;
      margin-left: 5px;
    }
    .center_icon {
      color: green;
      cursor: pointer;
      margin-left: 5px;
    }
    .close_icon {
      cursor: pointer;
      margin-left: 5px;
    }
  }
}
.icon_picture {
  font-size: 24px;
}
.upload_li ::v-deep .el-progress-bar {
  padding-right: 0 !important;
  margin-right: 0 !important;
}
.upload_li ::v-deep .el-progress__text {
  display: none !important;
}
.upload-demo ::v-deep .el-upload-list {
  display: none !important;
}
.avatar-uploader ::v-deep .el-upload-list {
  display: none !important;
}
</style>
