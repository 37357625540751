<template>
  <div class="avue-top">
    <div class="header">
      <img
        :src="require('../../assets/images/work_logo.png')"
        alt=""
        style="width: 220px;"
        srcset="">
      <div class="top_right">

        <el-dropdown
          class="logon_header"
          style="margin-left: 30px;"
          @command="handleCommand">
          <div
            class="el-dropdown-link">
            <span
              style="color:#FFF;">{{ studentInfo.nickName
 }}</span><i
              class="el-icon-arrow-down el-icon--right" />
          </div>
          <el-dropdown-menu
            slot="dropdown">
            <!-- <el-dropdown-item
            command="setInfo">个人信息</el-dropdown-item> -->
            <!-- <el-dropdown-item
            command="setPsw">修改密码</el-dropdown-item> -->
            <!-- <el-dropdown-item
            command="setSt">课表查看</el-dropdown-item> -->
            <!-- <el-dropdown-item
            command="openDianli">查看典礼</el-dropdown-item> -->
            <!-- <el-dropdown-item
            command="setEwm">下载APP</el-dropdown-item>
          <el-dropdown-item
            command="downFile">相关证明下载</el-dropdown-item>
          <el-dropdown-item
            command="applyFor">相关申请</el-dropdown-item> -->
            <el-dropdown-item
              command="signOut">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </div>
    </div>

    <!-- 修改密码弹框 -->
    <el-dialog title="修改密码"
      :visible.sync="dialogFormVisible"
      width="500px"
      class="reset_pwd">
      <el-form ref="ruleForm"
        :model="ruleForm"
        :rules="rules">
        <el-form-item
          prop="pwd">
          <el-input
            v-model="ruleForm.pwd"
            :type="type"
            placeholder="请输入原始登录密码">
            <i slot="prefix"
              class="el-icon-lock" />
            <i slot="suffix"
              style="font-size: 18px; cursor: pointer; margin-right: 10px"
              class="el-input__icon icon"
              :class="iconClass"
              @click="showPassword" />
          </el-input>
        </el-form-item>
        <el-form-item
          prop="newPwd">
          <el-input
            v-model="ruleForm.newPwd"
            :type="type"
            placeholder="请输入新登录密码">
            <i slot="prefix"
              class="el-icon-lock" />
            <i slot="suffix"
              style="font-size: 18px; cursor: pointer; margin-right: 10px"
              class="el-input__icon icon"
              :class="iconClass"
              @click="showPassword" />
          </el-input>
        </el-form-item>
        <el-form-item
          prop="newPwds">
          <el-input
            v-model="ruleForm.newPwds"
            :type="type"
            placeholder="请确认新登录密码">
            <i slot="prefix"
              class="el-icon-lock" />
            <i slot="suffix"
              style="font-size: 18px; cursor: pointer; margin-right: 10px"
              class="el-input__icon icon"
              :class="iconClass"
              @click="showPassword" />
          </el-input>
        </el-form-item>
        <div
          class="reset_info">
          如忘记密码，请联系管理员进行重置
        </div>
      </el-form>
      <div slot="footer"
        class="dialog-footer">
        <el-button
          @click="dialogFormVisible = false">取
          消</el-button>
        <el-button
          type="primary"
          @click="changeP">确
          定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getStore } from '@/util/store'
export default {
  data() {
    // 校验原始密码是否正确
    var checkPwd = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入原始登录密码'))
      } else if (this.$store.state.user.oldpassword !== this.ruleForm.pwd) {
        callback(new Error('原始密码不正确'))
      } else {
        callback()
      }
    }
    // 校验新密码
    var checkPwds = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入确认新登录密码'))
      } else if (this.ruleForm.newPwd !== this.ruleForm.newPwds) {
        callback(new Error('新登录密码不一致'))
      } else {
        callback()
      }
    }
    return {
      topMenu: [],
      isActive: 0,
      loading: false,
      iconClass: 'ion-eye-disabled',
      type: 'password',
      oldPwd: '',
      command: '',
      messageTotal: 0,
      ruleForm: {
        pwd: '',
        newPwd: '',
        newPwds: '',
        userId: ''
      },
      userInfo: {},
      studentInfo: {},
      classData: {},
      // 表达校验规则
      rules: {
        pwd: [
          { min: 6, max: 30, message: '密码长度在6位到30位之间', trigger: 'blur' },
          { required: true, validator: checkPwd, trigger: 'blur' }
        ],
        newPwd: [
          { min: 6, max: 30, message: '密码长度在6位到30位之间', trigger: 'blur' },
          { required: true, message: '请输入新登录密码', trigger: 'blur' }
        ],
        newPwds: [
          { min: 6, max: 30, message: '密码长度在6位到30位之间', trigger: 'blur' },
          { required: true, validator: checkPwds, trigger: 'blur' }
        ],
        applyType: [{ required: true, message: '请选择申请类型', trigger: 'blur' }],
        content: [{ required: true, message: '请填写申请详细内容及原因', trigger: 'blur' }]
      },
      dialogFormVisible: false,
      formLabelWidth: '100px',
      videoUrl: '',
      dp: null,
      options: {
        autoplay: false, // 设置自动播放
        muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音（Chrome66及以上版本，禁止音视频的自动播放）
        preload: 'auto', // 预加载
        controls: true // 显示播放的控件
      },
      videoDialogVisible: false,
      videoTitle: '',
      downVisible: false,
      downVisibleFor: false,
      downUrl: '',
      downSelect: [],
      ruleFormFor: {
        applyType: '',
        content: ''
      }
    }
  },
  computed: {},
  watch: {
    total(val) {
      this.messageTotal = val
    },
    tenantInfo(val) {}
  },

  mounted() {
    // this.userInfo = getStore({ name: 'tenantInfo' }) ? getStore({ name: 'tenantInfo' }) : ''
    // this.studentInfo = getStore({ name: 'studentInfo' }) ? getStore({ name: 'studentInfo' }) : {}
    // console.log(this.studentInfo);
    // let info = JSON.parse(localStorage.getItem('zyjn-student-userInfo')).content

    this.studentInfo = JSON.parse(localStorage.getItem('zyjn-student-userInfo')).content
  },
  methods: {
    // 是否显示密码
    showPassword() {
      if (this.type === 'password') {
        this.type = 'text'
        this.iconClass = 'ion-eye'
      } else {
        this.type = 'password'
        this.iconClass = 'ion-eye-disabled'
      }
    },
    // 登出
    handleCommand(command) {
      if (command === 'signOut') {
        // this.logout()
        this.$store.dispatch('user/logout').then(() => {
          const hrefUrl = window.location.href.split('#')[0]
          window.location.href = hrefUrl + '#/login'
          // window.location.href = '/zyjnstudent/#/login'
          // this.$router.push({ path: '/yxbstudent/#/login' })
        })
      } else if (command === 'setEwm') {
        this.$alert(`<img src="${this.ewmImg}" style="width:200px;display:block; margin:0 auto;" />`, '下载APP', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '关闭'
        })
      } else if (command === 'setInfo') {
        this.command = 'setInfo'
        // this.$emit('InfoDetail')
        this.$router.push({
          path: '/info'
        })
      } else if (command === 'setSt') {
        this.$refs.modalTime.open()
      } else if (command == 'openDianli') {
        this.$refs.modalDianli.open()
      } else if (command == 'downFile') {
        this.downUrl = ''
        this.downVisible = true
      } else if (command == 'applyFor') {
        this.downVisibleFor = true
        this.ruleFormFor = {
          applyType: '',
          content: ''
        }
        if (this.$refs.ruleFormFor) {
          this.$refs.ruleFormFor.resetFields()
        }
      } else {
        this.dialogFormVisible = true
      }
    },
    // 修改密码确认
    changeP() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.ruleForm.studentId = window.localStorage.getItem('user_id')
          this.$post('CHANGEP', { ...this.ruleForm })
            .then(res => {
              this.dialogFormVisible = false
              this.loading = true
              this.$message.success('修改密码成功！')
              setTimeout(() => {
                window.localStorage.clear()
                window.location.href = '/yxbstudent/#/login'
              }, 1000)
            })
            .catch(error => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.bule-theme {
  .avue-top {
    background: #fff;
  }
}
.top_left {
  color: #4e5969;
  font-size: 14px;
  padding-left: 35px;
  .active {
    color: #292c35;
  }
}
.top_right {
  display: flex;
  align-items: center;
}
.search_title {
  display: flex;
  flex-direction: row;
  align-content: center;
  color: #4e5969;
  font-size: 14px;
  div {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-right: 40px;
  }
  img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: 3px;
  }
}
.reset_pwd {
  .reset_info {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #f34958;
  }
}
</style>
<style>
.reset_pwd .el-input__inner {
  border-radius: 25px;
}
.reset_pwd .el-color-picker__icon,
.el-input,
.el-textarea {
  width: 80%;
}
.reset_pwd .el-form-item {
  text-align: center;
}
.reset_pwd .el-input__prefix,
.el-input__suffix {
  margin-left: 5px;
}
.reset_pwd .el-icon-lock:before {
  color: #525bf3;
}
.reset_pwd .el-dialog__footer {
  text-align: center;
  border-radius: 25px;
}
</style>
