<!--
 * @Description:: 育小帮科技有限公司
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-02-14 11:29:12
 * @LastEditors: lidandan
 * @LastEditTime: 2023-02-24 15:16:15
 * @FilePath: \yxb3_student_pc\src\components\modal\modal.vue
-->
<template>
  <!-- 组件开始 -->
  <el-dialog :title="title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    :width="width"
    :before-close="handleClose">
    <slot />
    <!-- 组件按钮 -->
    <span slot="footer"
      class="dialog-footer"
      v-if="cancelText || okText">
      <el-button
        v-if="cancelText"
        @click="handleClose">{{
        cancelText
      }}</el-button>
      <el-button v-if="okText"
        type="primary"
        @click="handleSubmit">{{
        okText
      }}</el-button>
    </span>
  </el-dialog>
  <!-- 组件结束 -->
</template>

<script>
export default {
  name: 'Yxb3NewModal',
  props: {
    // 头部
    title: {
      type: String,
      default: ''
    },
    // 取消按钮文字
    cancelText: {
      type: String,
      default: '取 消'
    },
    // 确认按钮文字
    okText: {
      type: String,
      default: '确 定'
    },
    // 宽度
    width: {
      type: String,
      default: '700px'
    },
    // 显示隐藏
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {
    // 关闭方法
    handleClose() {
      this.$emit('cancel')
    },
    // 确定方法
    handleSubmit() {
      this.$emit('ok')
    }
  }
}
</script>

<style lang="scss" scoped></style>
