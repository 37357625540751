/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-08-24 10:51:05
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-02-19 09:00:50
 * @FilePath: /stu_exam/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import Element from 'element-ui'
import vueEsign from 'vue-esign'
import 'element-ui/lib/theme-chalk/index.css'
import { REQUEST } from './api/http'
import router from './router'
import store from './store'
import './styles/common.scss'
import '@/util/router.js'
import Modal from '@/components/modal/modal.vue'
import { getDicts } from '@/api/common/dict'
import BQuestion from '@/components/quill_editor/question.vue'

Vue.prototype.$post = REQUEST.POST
Vue.prototype.$https = REQUEST.POSTS
Vue.prototype.$get = REQUEST.GET
Vue.prototype.$POSTFILE = REQUEST.POSTFILE
Vue.prototype.$getDicts = getDicts

Vue.config.productionTip = false

Vue.component('BModal', Modal)
Vue.component('b-question', BQuestion)

Vue.use(Element)
Vue.use(vueEsign)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
