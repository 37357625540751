/*
 * @Description:: 亿学堂学生端
 * @Version: 1.0
 * @Author: lidandan
 * @Date: 2023-10-30 14:22:39
 * @LastEditors: lidandan
 * @LastEditTime: 2023-10-31 09:54:49
 * @FilePath: \yzjn_student_pc\src\api\common\dict.js
 */
import request from '@/api/request.js'

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
  return request({
    url: '/system/dict/data/type/' + dictType,
    method: 'get'
  })
}
