/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2022-10-18 14:05:31
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-08-16 16:36:34
 * @FilePath: /yxb_zyjn/src/store/index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

const modulesFile = require.context('./modules', true, /\.js$/)

const modules = modulesFile.keys().reduce((acc, cur) => {
  const key = cur.replace(/(.\/|.js$)/g, '')
  acc[key] = modulesFile(cur).default
  return acc
}, {})

const store = new Vuex.Store({
  state: {
    token: '',
    staff: {},
    menuList: [],
    mainHeight: 0
  },
  mutations: {
    SET_STAFF (state, staff) {
      state.staff = staff
    },
    SET_TOKEN: (state, data) => {
      state.token = data
    },
    SET_MENU: (state, data) => {
      state.menuList = data
    },
    SET_HEIGHT: (state, data) => {
      state.mainHeight = data
    }

  },
  actions: {

  },
  modules,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
})

export default store
