/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-04-25 15:58:33
 * @LastEditors: 孙嘉欣
 * @LastEditTime: 2024-03-23 17:03:00
 * @FilePath: \stu_exam\src\api\api.js
 */
let baseUrl
switch (process.env.NODE_ENV) {
  case 'development':
    // baseUrl = 'http://39.99.236.132:9020'
    baseUrl = ''
    break
  case 'production':
    // baseUrl = 'http://39.99.236.132:9030'//dev
    // baseUrl = 'http://39.99.236.132:9031'// test
    // baseUrl = 'https://examtest.yxbyun.com'// test
    baseUrl = 'https://exam.yxbyun.com'// 22222

    break
}
export const HTTP_URI = {
  BASEURL: baseUrl,
  WSUPLOAD: 'https://bfjyjt.up17.v1.wcsapi.com/', // 网宿云上传
  IMGURL: 'https://video.bfjyjt.cn', // 网宿云反显地址

  GETPICTURE: baseUrl + '/admin/tenant/getBg', // 获取登录页背景
  // 字典
  SYSNATION: baseUrl + '/student/sysDict/type/sys_nation', // 民族
  SYSSUBLEVEL: baseUrl + '/student/sysDict/type/sys_sub_level', // 层次
  SYSSTUDYFORM: baseUrl + '/student/sysDict/type/sys_study_form', // 学习形式
  SYSCOUNTENANCE: baseUrl + '/student/sysDict/type/sys_countenance', // 政治面貌
  CERTIFICATESTYPE: baseUrl + '/student/sysDict/type/certificates_type', // 证件类型
  SYSEDU: baseUrl + '/student/sysDict/type/sys_edu' // 文化程度
}
export default { HTTP_URI, baseUrl }
