import { getRouters } from "@/api/menu";
import layout from '@/components/layout/layout.vue'
import mainView from '@/components/layout/mainView.vue'
import router, { routes, dynamicRoutes } from "@/router";
import auth from "@/plugins/auth";
export default {
  // 生成路由
  GenerateRoutes() {
    return new Promise((resolve) => {
      // 向后端请求路由数据
      getRouters().then((res) => {

        const sdata = JSON.parse(JSON.stringify(res.data));
        const rdata = JSON.parse(JSON.stringify(res.data));
        const sidebarRoutes = filterAsyncRouter(sdata);
        const rewriteRoutes = filterAsyncRouter(rdata, false, true);
        const asyncRoutes = filterDynamicRoutes(dynamicRoutes);
        rewriteRoutes.push({ path: "*", redirect: "/404", hidden: true });
        asyncRoutes.forEach(item => {
          router.addRoute(item)
        })

        const finishRouter = [
          {
            path: "/",
            name: "index",
            component: mainView,
            hidden: true,
            children: rewriteRoutes,
          },
        ];
        this.state.permissionRoutes = finishRouter;
        this.state.sidebarRoutes = sidebarRoutes;

        resolve(finishRouter);
      });
    });
  },
};

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter((route) => {
    if (type && route.children) {
      route.children = filterChildren(route.children);
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === "Layout") {
        route.component = layout;
      } else if (route.component === "ParentView") {
        // route.component = mainView;
      } else if (route.component === "InnerLink") {
        // route.component = mainView;
      } else {
        route.path = `/${route.component}`;
        route.component = loadView(route.component);
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type);
    } else {
      delete route["children"];
      delete route["redirect"];
    }
    return true;
  });
}

function filterChildren(childrenMap, lastRouter = false) {
  var children = [];
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (el.component === "ParentView" && !lastRouter) {
        el.children.forEach((c) => {
          c.path = el.path + "/" + c.path;
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }
          children.push(c);
        });
        return;
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + "/" + el.path;
    }
    children = children.concat(el);
  });
  return children;
}

// 动态路由遍历，验证是否具备权限
export function filterDynamicRoutes(routes) {
  const res = [];
  routes.forEach((route) => {
    if (route.permissions) {
      if (auth.hasPermiOr(route.permissions)) {
        res.push(route);
      }
    } else if (route.roles) {
      if (auth.hasRoleOr(route.roles)) {
        res.push(route);
      }
    }
  });
  return res;
}
export const loadView = (view) => {
  if (process.env.NODE_ENV === "development") {
    return (resolve) => require([`@/views/${view}`], resolve);
  } else {
    // 使用 import 实现生产环境的路由懒加载
    return () => import(`@/views/${view}`);
  }
};
