/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-04-04 15:38:15
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-04-20 17:31:25
 * @FilePath: /yxb3-admin/src/components/editor/quill_editor/toolbar.js
 */
export const toolbarOptions = [
  // ["image", "bold", "italic", "link"], // 加粗，倾斜，下划线，删除线
  [ "bold", "italic"], // 加粗，倾斜，下划线，删除线
  // ["blockquote", "code-block"], // 引号,代码

  // [{ list: "ordered" }, { list: "bullet" }], // 有序列表，无序列表

  [{ indent: "-1" }, { indent: "+1" }], // 左移，右移
  [{ direction: "rtl" }], // 左右对齐
  [{ size: [false, "10px", "14px", "16px", "18px", "20px", "24px"] }], // 字体大小
  [{ header: [1, 2, 3, 4, 5, false] }], // 标题大小
  // [{ color: [] }, { background: [] }], // 文字颜色，文字背景
  // [
  //   {
  //     font: [
  //       "SimSun",
  //       "SimHei",
  //       "Microsoft-YaHei",
  //       "KaiTi",
  //       "FangSong",
  //       "Arial",
  //       "Times-New-Roman",
  //       "sans-serif",
  //     ],
  //   },
  // ], // 字体风格
  [{ align: [] }], // 对齐方式
  ["clean"], // 图片，链接，清除，自定义行为
];
