<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: lidandan
 * @Date: 2023-02-22 13:51:46
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-06 11:36:22
 * @FilePath: /zyjn_student_pc/src/components/sidebar/index.vue
-->
<template>
  <div class="menu_box">
    <div class="logo">
      <img v-if="logo"
        :src="logo" style="
          width: 90%;
          position: relative;
          top: 5px;
          margin-right: 5px;
          height: 57px;
        ">
      <span
        v-else>亿学宝平台</span>
    </div>
    <!-- 导航 -->
    <div class="menu_ui">
      <el-menu
        class="custom-menu el-menu-vertical-demo"
        :default-active="activeIndex"
        :router="true"
        :unique-opened="true">
        <template
          v-for="(item,index) in menuList">
          <el-menu-item
            v-if="!item.children"
            v-show="!item.hidden"
            :key="'index' + index"
            :index="item.path">
            <i class="iconfont"
              style="margin-right: 10px;"
              :class="item.meta.icon?item.meta.icon:'el-icon-menu'" />
            <span
              slot="title">{{ item.meta.title }}</span>
          </el-menu-item>
          <el-submenu v-else
            :key="'index' + index + '1'"
            class="submenu"
            :index="item.path">
            <template
              slot="title"><i
                class="iconfont"
                style="margin-right: 10px;"
                :class="item.meta.icon?item.meta.icon:'el-icon-menu'" />{{ item.meta.title }}</template>
            <el-menu-item
              v-for="it in item.children"
              v-show="!it.hidden"
              :key="it.path"
              :index="it.path">
              <i
                class="mr-10" />
              <span
                slot="title">{{ it.meta.title }}</span>
            </el-menu-item>
          </el-submenu>
        </template>
      </el-menu>
    </div>
  </div>
</template>
<script>
import website from '@/const/website'
import store from '@/layouts/store'
import { routes } from '@/router/index'
import { mapGetters } from 'vuex'
import { getStore } from '@/util/store'
import { HTTP_URI } from '@/api/api'

export default {
  name: 'Sidebar',
  components: {},
  data() {
    return {
      activeIndex: '/',
      stuBool: false,
      menuList: [
        {
          name: '首页',
          path: '/',
          children: []
        },
        {
          name: '学习中心',
          path: '/learningCenter',
          children: [
            {
              name: '班级学习',
              path: '/learningCenter/classLearning'
            },
            {
              name: '个人学习',
              path: '/learningCenter/individualLearning'
            }
          ]
        },
        {
          name: '个人中心',
          path: '/individualCenter',
          children: [
            {
              name: '个人信息',
              path: '/individualCenter/individualInfo'
            },
            {
              name: '学习档案',
              path: '/individualCenter/learningFile'
            }
          ]
        }
      ],
      logo: '', // 背景图
      state: store.state,
      menuList: []
    }
  },
  computed: {
    ...mapGetters({
      sidebarRouters: 'permission/getSidebarRouters'
    })
  },
  watch: {
    $route: {
      handler(to, from) {
        this.logo = this.$store.state.user.tenantInfo.zpbs?HTTP_URI.IMGURL+this.$store.state.user.tenantInfo.zpbs:''
        this.activeIndex = to.path
        // 点击课程中心进入学习时
        // if (this.activeIndex == "/courseInfo") {
        //   this.activeIndex = "/courseCenter";
        // }
        // // 点击考试中心作答时
        // if (this.activeIndex == "/testPaper") {
        //   this.activeIndex = "/examCenter";
        // }
        // // 点击考试中心统考作答时
        // if (this.activeIndex == "/unifyPaper") {
        //   this.activeIndex = "/examCenter";
        // }
        // window.localStorage.setItem("activeMenuPath", to.path);
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    //  this.getMyEdit();
    // if (
    //   window.localStorage.getItem(website.key+"activeMenuPath") &&
    //   window.localStorage.getItem(website.key+"activeMenuPath") != 0
    // ) {
    //   this.activeIndex = window.localStorage.getItem(website.key+"activeMenuPath");
    // } else {
    //   this.activeIndex = "/";
    // }
    this.$nextTick(() => {
      this.menuList = routes.filter(item => {
        return item.path == '/'
      })[0].children
      const menuList = this.state.sidebarRoutes
      menuList.map(item => {
        item.meta.title = item.meta.title.substring(3, item.meta.title.length)
      })
      this.menuList = this.menuList.concat(menuList)
      this.$forceUpdate()
    })
  },
  methods: {
    gotoPage(item, index) {
      console.log(item)
      if (item.name == '毕业办理') {
        this.$router.push({
          path: item.path,
          // 参数
          query: {
            stuBool: this.stuBool
          }
        })
      } else {
        this.$router.push({
          path: item.path
        })
      }
      // this.activeIndex = item.path
      window.localStorage.setItem(website.key + 'activeMenuPath', item.path)
    },
    // 学生端是否可以编辑学生毕业信息接口
    getMyEdit() {
      this.$post('GETEDITAUTHSTU', {
        studentId: window.localStorage.getItem('user_id')
      }).then(res => {
        console.log(res.data)
        this.stuBool = res.data
      })
    }
  }
}
</script>
<style>
.el-menu-item i {
  color: #ffffff !important;
  font-size: 20px;
}
.el-menu-item.is-active i {
  color: #3b7bfc !important;
}
.el-menu-item:hover i {
  color: #3b7bfc !important;
}
.el-submenu__title:hover i {
  color: #3b7bfc !important;
}
</style>
<style lang="scss" scoped>
.mr-10 {
  margin-right: 25px;
}
::v-deep .el-menu-item {
  padding-left: 20px !important;
  color: #fff;
  margin: 5px 0;
}
::v-deep .el-menu-item:hover .el-icon-menu {
  color: #3b7bfc;
}
::v-deep .el-menu-item:hover .el-icon-menu i {
  color: #3b7bfc !important;
  font-size: 20px;
}
::v-deep .el-menu-item:hover i {
  color: #3b7bfc !important;
  font-size: 20px;
}
::v-deep .el-submenu__title {
  padding-left: 20px !important;
  color: #fff;
}
::v-deep .el-submenu__title i {
  color: #fff;
}
::v-deep .el-submenu__title:hover .el-icon-menu {
  color: #3b7bfc;
}
::v-deep .el-menu--inline {
  background-color: #525bf3 !important;
}

::v-deep .custom-menu {
  background: none; /* 去掉背景颜色 */
  border: none; /* 去掉边框 */
  padding: 0; /* 去掉内边距 */
  margin: 5px 0; /* 去掉外边距 */
  width: 100%;
  /* 添加其他样式调整 */
}
.blue-theme {
  .logo {
    background: #3b7bfc;
    line-height: 62px;
    text-align: center;
    color: #ffffff;
    border-bottom: 2px solid #3b7bfc;
  }
}
.menu_box {
  height: 100vh;
  background: #525bf3;
  .logo {
    height: 62px;
    font-size: 20px;
    background: #ffffff;
    line-height: 62px;
    text-align: center;
    color: #3b7bfc;
    border-bottom: 2px solid #f5f5f5;
  }
  .menu_ui {
    padding: 0 10px;
    margin: 10px auto;
    background-color: #525bf3;
    // .menu_li {
    //   line-height: 50px;
    //   border-radius: 5px;
    //   background: #ffffff;
    //   text-align: center;
    //   margin: 2px 0;
    //   cursor: pointer;
    // }
    // .menu_li.active {
    //   background: #3b7bfc;
    //   color: #ffffff;
    // }
  }
}
.el-menu--horizontal > .el-submenu .el-submenu__title,
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title,
.el-submenu__title i {
  color: #fff;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 2px solid #3b7bfc;
  color: #fff;
}
//鼠标悬浮时，子菜单的样式
.el-menu-item:hover {
  outline: 0 !important;
  color: #525bf3 !important;
  background: #fff !important;
  border-radius: 10px;
}
// 一级菜单选中的样式
::v-deep.el-menu-item.is-active {
  color: #3b7bfc !important;
  border-bottom-color: transparent !important;
  background: #fff !important;
  border-radius: 10px;
}
// 二级菜单 选中的样式
::v-deep .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  color: #3b7bfc !important;
  border-bottom-color: transparent !important;
}
.el-icon-menu {
  color: #fff;
}
//鼠标悬浮时，主菜单的样式
.el-submenu__title:focus,
::v-deep .el-submenu__title:hover {
  outline: 0 !important;
  color: #3b7bfc !important;
  background: #fff !important;
  border-radius: 10px;
  .el-submenu__title > .el-icon-menu {
    color: #3b7bfc !important;
  }
}
.el-menu--horizontal:focus,
.el-menu--horizontal:hover {
  outline: 0 !important;
  color: #fff !important;
  background: none !important;
}
.nav-menu-son .el-menu--horizontal:hover {
  background: pink !important;
}
.el-menu {
  background-color: none !important;
}
// .el-menu:hover{
// 	.el-icon-menu{
//     color: #3b7bfc!important;
//   }
// }

.el-menu-item a:hover {
  background: none;
}
.el-menu--popup-bottom-start {
  margin-top: -2px;
}
.el-menu--popup {
  background: none !important;
  padding: 0 0;
  min-width: 120px !important;
  line-height: 40px !important;
  box-shadow: 0 4px 8px 0 rgba(25, 14, 109, 0.13);
  border-radius: 5px;
}
.el-menu--horizontal .el-menu {
  background: none;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  background-color: rgba(255, 255, 255, 0.6);
  float: none;
  height: 36px;
  line-height: 36px;
  padding: 0 22px;
  color: #929292 !important;
  z-index: 100;
  font-weight: 500;
}
//二次菜单悬浮及背景样式
.el-menu--popup-bottom-start .el-menu-item:hover {
  color: rgba(86, 86, 86, 1) !important;
}
.el-menu--popup-bottom-start .el-menu-item {
  background: #fff !important;
}
</style>
