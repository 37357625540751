/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2022-10-18 14:05:31
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-02-20 16:10:48
 * @FilePath: /stu_exam/src/util/router.js
 */
import router from '@/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import LayoutStore from '@/layouts/index'
import store from '@/store/index'
import { getStore } from '@/util/store'
import { Message, MessageBox } from 'element-ui'
import { selectTenantInfo } from '@/api/login'

NProgress.configure({ showSpinner: false })

function isTokenExpired() {
  const token = getStore({ name: 'token' })
  return !!token
}
// function isTenantExpired() {
//   const tenantInfo = getStore({ name: 'tenantInfo' })
//   return !!tenantInfo
// }

const whiteList = ['login', 'white']

router.beforeEach((to, from, next) => {
  NProgress.start()
  console.log(to.name, '222')
  // 在免登录白名单，直接进入
  if (whiteList.includes(to.name)) {
    next()
    document.title = '考试系统-' + to.meta.title
    NProgress.done()
  } else {
    // 没有token
    if (!isTokenExpired()) {
      next(`/login?redirect=${to.path}`)
      // 否则全部重定向到登录页
      NProgress.done()
    } else {
      NProgress.done()
      document.title = '考试系统-' + to.meta.title
      const isEmptyRoute = LayoutStore.isEmptyPermissionRoute()
      if (isEmptyRoute) {
        store
          .dispatch('user/GetInfo')
          .then((userRes) => {
            LayoutStore.GenerateRoutes().then(accessRoutes => {
              accessRoutes.forEach(item => {
                router.addRoute(item)
              })
              next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
            })
          })
          .catch(err => {
            console.log(err, 'error')
            store.dispatch('user/logout').then(() => {
              Message.error(err)
              next({ path: '/' })
            })
          })
      } else {
        next()
      }
    }
  }
})

router.afterEach((to, from) => {
  NProgress.done()
})
