/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-03-02 13:14:18
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-03-18 18:29:51
 * @FilePath: /yxb3_student_pc/src/api/http.js
 */
import request from './axios'
import { HTTP_URI } from './api'
export const REQUEST = {
  POST: function (businessType, datas, signal) {
    if (!HTTP_URI[businessType]) return
    return request({
      url: HTTP_URI[businessType],
      method: 'post',
      data: datas,
      signal: signal
    })
  },
  POSTS: function (businessType, datas) {
    if (!HTTP_URI[businessType]) return
    return request({
      url: HTTP_URI[businessType],
      method: 'post',
      params: datas
    })
  },
  GET: function (businessType, params) {
    return request({
      url: HTTP_URI[businessType],
      method: 'get',
      params: params
    })
  },
  POSTFILE: function (businessType, params) {
    return request({
      url: HTTP_URI[businessType],
      method: 'post',
      data: params,
      headers: { 'Content-Type': 'multipart/form-data' } // 设置请求头为文件流
    })
  }
}
export default REQUEST
