
import store from './store/index'



function install(Vue, options) {
  store.start(options)
  Vue.prototype.$layoutStore = store
}

store.install = install


export default store
