/*
 * @Description:: 亿学堂学生端
 * @Version: 1.0
 * @Author: lidandan
 * @Date: 2023-04-10 20:26:22
 * @LastEditors: 王东润
 * @LastEditTime: 2023-07-29 17:31:52
 * @FilePath: \yxb3_student_pc\src\store\modules\exam.js
 */
import { getStore, setStore } from '@/util/store'
import { getcurrentTime, calcDate } from '@/util/date'
import store from '@/store'
import {
  updateStudentRecord,
  insertStudentTaskRecord,
  insertStudentExamRecord,
  insertStudentAgainExamRecord,
  updateStudentAgainRecord,
  updateStudentPaperTaskExamSubmit
} from '@/api/exam'
import router from '../../router'
import Vue from 'vue'
const that = new Vue()
let refreshTime = null
const exam = {
  state: {
    // 考试试卷数据
    examPagerData:
      getStore({
        name: 'examPagerData'
      }) || {},
    // 试卷详情
    examDetailJson:
      getStore({
        name: 'examDetailJson'
      }) || 0
  },
  actions: {
    //  判断考试时间是否结束
    setExamTimer({ state, commit }, time) {
      // 时间结束退出
      if (time == 0) {
        return
      }
      if (refreshTime) {
        clearInterval(refreshTime)
        refreshTime = null
      }
      refreshTime = setInterval(() => {
        const pager = state.examPagerData
        // 判断考试时间是否结束
        let finish = pager.finishTime
        finish = finish * 1 - 10 * 60 * 1000 - new Date().valueOf()
        const time = parseInt(finish / 1000)

        // 时间到
        if (time <= 0) {
          // 清空倒计时
          clearInterval(refreshTime)
          refreshTime = null
          // 时间到提示交卷
          store.dispatch('timeEndSubmit')
        }
      }, 1000)
    },
    // 时间到 交卷
    timeEndSubmit({ state, commit }, param) {
      // 试卷信息
      const pager = state.examPagerData
      if (pager.examType == '3') {
        return
      }
      that
        .$confirm('考试已结束，系统已自动提交试卷', '考试结束提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          showCancelButton: false, // 不显示取消按钮
          showClose: false, // 是否显示右上角的x
          closeOnClickModal: false, // 是否可以点击空白处关闭弹窗
          type: 'warning'
        })
        .then(() => {
          // 提交考试
          if (pager.examType == '1') {
            store.dispatch('finishPager', { isFinish: 2 }).then(() => {
              that.$message.success('交卷成功')
              // 若当前在考试页面，则退出回到考试中心
              if (router.app._route.name == 'testPaper') {
                // 跳转路由
                if (pager.fromPath == '/courseInfo') {
                  router.push({
                    path: '/courseInfo',
                    query: {
                      id: pager.pathId,
                      termId: pager.pathTermId,
                      courseName: pager.pathCourseName,
                      isSemester: pager.pathIsSemester
                    }
                  })
                }
                //  若当前在考试中心,则强制刷新页面
                else if (router.app._route.name == 'examCenter') {
                  location.reload()
                } else {
                  router.push({
                    path: '/examCenter',
                    query: {
                      tab: '1'
                    }
                  })
                }
              }
            })
          }
          // 提交补考
          if (pager.examType == '2') {
            store.dispatch('finishAgainPager', { isFinish: 2 }).then(() => {
              that.$message.success('交卷成功')
              // 若当前在考试页面，则退出回到考试中心
              if (router.app._route.name == 'testPaper') {
                // 跳转路由
                if (pager.fromPath == '/courseInfo') {
                  router.push({
                    path: '/courseInfo',
                    query: {
                      id: pager.pathId,
                      termId: pager.pathTermId,
                      courseName: pager.pathCourseName,
                      isSemester: pager.pathIsSemester
                    }
                  })
                }
                //  若当前在考试中心,则强制刷新页面
                else if (router.app._route.name == 'examCenter') {
                  location.reload()
                } else {
                  router.push({
                    path: '/examCenter',
                    query: {
                      tab: '2'
                    }
                  })
                }
              }
            })
          }
          // 提交作业
          if (pager.examType == '3') {
            store.dispatch('finishTaskPager', { isFinish: 2 }).then(() => {
              that.$message.success('交卷成功')
              // 若当前在考试页面，则退出回到考试中心
              if (router.app._route.name == 'testPaper') {
                // 跳转路由
                if (pager.fromPath == '/courseInfo') {
                  router.push({
                    path: '/courseInfo',
                    query: {
                      id: pager.pathId,
                      termId: pager.pathTermId,
                      courseName: pager.pathCourseName,
                      isSemester: pager.pathIsSemester
                    }
                  })
                }
                //  若当前在考试中心,则强制刷新页面
                else if (router.app._route.name == 'examCenter') {
                  location.reload()
                } else {
                  router.push({
                    path: '/examCenter',
                    query: {
                      tab: '3'
                    }
                  })
                }
              }
            })
          }
        })
    },
    // 考试未结束，切换路由，或者后退浏览器
    gobackPager({ state, commit }) {
      // 试卷信息
      const pager = state.examPagerData
      that
        .$confirm('考试正在作答，如继续您的操作，系统将自动提交试卷请 谨慎操作', '防作弊提示', {
          confirmButtonText: '继续作答',
          cancelButtonText: '确认交卷',
          showCancelButton: true, // 不显示取消按钮
          showClose: false, // 是否显示右上角的x
          closeOnClickModal: false, // 是否可以点击空白处关闭弹窗
          type: 'warning'
        })
        .then(() => {})
        .catch(() => {
          // 提交考试
          if (pager.examType == '1') {
            store.dispatch('finishPager', { isFinish: 2 }).then(() => {
              that.$message.success('交卷成功')
              // 退出全屏
              if (document.exitFullscreen) {
                document.exitFullscreen()
              } else if (document.mozCancelFullScreen) { // 兼容 Firefox
                document.mozCancelFullScreen()
              } else if (document.webkitExitFullscreen) { // 兼容 Chrome, Safari和Opera
                document.webkitExitFullscreen()
              } else if (document.msExitFullscreen) { // 兼容 IE/Edge
                document.msExitFullscreen()
              }
              // 若当前在考试页面，则退出回到考试中心
              if (router.app._route.name == 'testPaper') {
                // 跳转路由
                if (pager.fromPath == '/courseInfo') {
                  router.push({
                    path: '/courseInfo',
                    query: {
                      id: pager.pathId,
                      termId: pager.pathTermId,
                      courseName: pager.pathCourseName,
                      isSemester: pager.pathIsSemester
                    }
                  })
                }
                //  若当前在考试中心,则强制刷新页面
                else if (router.app._route.name == 'examCenter') {
                  location.reload()
                } else {
                  router.push({
                    path: '/examCenter',
                    query: {
                      tab: '1'
                    }
                  })
                }
              }
            })
          }
          // 提交补考
          if (pager.examType == '2') {
            store.dispatch('finishAgainPager', { isFinish: 2 }).then(() => {
              that.$message.success('交卷成功')
              // 若当前在考试页面，则退出回到考试中心
              if (router.app._route.name == 'testPaper') {
                // 跳转路由
                if (pager.fromPath == '/courseInfo') {
                  router.push({
                    path: '/courseInfo',
                    query: {
                      id: pager.pathId,
                      termId: pager.pathTermId,
                      courseName: pager.pathCourseName,
                      isSemester: pager.pathIsSemester
                    }
                  })
                }
                //  若当前在考试中心,则强制刷新页面
                else if (router.app._route.name == 'examCenter') {
                  location.reload()
                } else {
                  router.push({
                    path: '/examCenter',
                    query: {
                      tab: '2'
                    }
                  })
                }
              }
            })
          }
          // 提交作业
          if (pager.examType == '3') {
            store.dispatch('finishTaskPager', { isFinish: 2 }).then(() => {
              that.$message.success('交卷成功')
              // 若当前在考试页面，则退出回到考试中心
              if (router.app._route.name == 'testPaper') {
                // 跳转路由
                if (pager.fromPath == '/courseInfo') {
                  router.push({
                    path: '/courseInfo',
                    query: {
                      id: pager.pathId,
                      termId: pager.pathTermId,
                      courseName: pager.pathCourseName,
                      isSemester: pager.pathIsSemester
                    }
                  })
                }
                //  若当前在考试中心,则强制刷新页面
                else if (router.app._route.name == 'examCenter') {
                  location.reload()
                } else {
                  router.push({
                    path: '/examCenter',
                    query: {
                      tab: '3'
                    }
                  })
                }
              }
            })
          }
        })
    },
    // 新增考试记录
    insertExamRecord({ state, commit }, param) {
      const pager = state.examPagerData
      // 参数
      const obj = {
        // studentId: window.localStorage.getItem('user_id'), //学生id
        // activityExamId: pager.activityExamId, //考试活动ID
        // teachingCourseId: pager.teachingCourseId, //教学计划课程ID
        // examPaperId: pager.examPaperId, //试卷ID
        // isFace: pager.examIsFacial, //是否人脸审核
        // facePass: pager.facePass, //人脸审核是否通过(1-待审核，2-未通过，3-已通过)
        // isFinish: param.isFinish,
        // startTime: getcurrentTime(), //开始作答时间
        // ip: '',
        // deptId: store.state.user.userInfo.deptId // 部门id

        studentId: param.studentId, // 学生id
        activityExamId: param.activityExamId, // 考试活动ID
        teachingCourseId: param.teachingCourseId, // 教学计划课程ID
        examPaperId: param.paperId, // 试卷ID
        isFace: pager.examIsFacial, // 是否人脸审核
        facePass: pager.examIsFacial == '1' ? '3' : pager.facePass, // 人脸审核是否通过(1-待审核，2-未通过，3-已通过)
        isFinish: param.isFinish,
        startTime: getcurrentTime(), // 开始作答时间
        ip: '',
        deptId: param.deptId // 部门id
      }
      // 调用接口
      return new Promise((resolve, reject) => {
        // 新增考试记录
        insertStudentExamRecord(obj, param.signal)
          .then(res => {
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 新增补考记录
    insertAgainExamRecord({ state, commit }, param) {
      const pager = state.examPagerData

      // 参数
      const obj = {
        // studentId: window.localStorage.getItem('user_id'), //学生id
        // teachingCourseId: pager.teachingCourseId, //教学计划课程ID
        // againExamId: pager.activityExamId, //补考ID
        // examPaperId: pager.examPaperId, //试卷ID
        // isFinish: param.isFinish,
        // startTime: getcurrentTime(), //开始作答时间
        // ip: '',
        // facePass: pager.facePass, //人脸审核是否通过(1-待审核，2-未通过，3-已通过)
        // isFace: pager.examIsFacial, //是否人脸审核
        // deptId: store.state.user.userInfo.deptId // 部门id

        studentId: param.studentId, // 学生id
        teachingCourseId: param.teachingCourseId, // 教学计划课程ID
        againExamId: param.activityExamId, // 补考ID
        examPaperId: param.paperId, // 试卷ID
        isFinish: param.isFinish,
        startTime: getcurrentTime(), // 开始作答时间
        ip: '',
        isFace: pager.examIsFacial, // 是否人脸审核
        facePass: pager.examIsFacial == '1' ? '3' : pager.facePass, // 人脸审核是否通过(1-待审核，2-未通过，3-已通过)
        deptId: param.deptId // 部门id
      }
      // 调用接口
      return new Promise((resolve, reject) => {
        // 新增考试记录
        insertStudentAgainExamRecord(obj, param.signal)
          .then(res => {
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 新增作业记录
    insertTask({ state, commit }, param) {
      const pager = state.examPagerData
      // 参数
      const obj = {
        // studentId: window.localStorage.getItem('user_id'), //学生id
        // activityTaskId: pager.activityExamId, //考试活动ID
        // teachingCourseId: pager.teachingCourseId, //教学计划课程ID
        // examPaperId: pager.examPaperId, //试卷ID
        // isFinish: param.isFinish,
        // startTime: getcurrentTime(), //开始作答时间
        // ip: '',
        // deptId: store.state.user.userInfo.deptId // 部门id

        studentId: param.studentId, // 学生id
        activityTaskId: param.activityExamId, // 考试活动ID
        teachingCourseId: param.teachingCourseId, // 教学计划课程ID
        examPaperId: param.paperId, // 试卷ID
        isFinish: param.isFinish,
        startTime: getcurrentTime(), // 开始作答时间
        ip: '',
        deptId: param.deptId // 部门id
      }
      // 调用接口
      return new Promise((resolve, reject) => {
        // 新增作业记录
        insertStudentTaskRecord(obj, param.signal)
          .then(res => {
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 考试交卷
    finishPager({ state, commit }, param) {
      const pager = state.examPagerData
      // 考试开始时间
      const startTime = pager.startTime * 1
      // 考试用时
      const longTime =
        calcDate(startTime, new Date().valueOf()).minutes > pager.examAnswerDuration * 1
          ? pager.examAnswerDuration
          : calcDate(startTime, new Date().valueOf()).minutes
      // 参数
      const obj = {
        hasSubjective: pager.hasSubjective, // 是否含有主观题 (0-否，1-是)
        studentId: window.localStorage.getItem('user_id'), // 学生id
        activityExamId: pager.activityExamId, // 考试活动ID
        teachingCourseId: pager.teachingCourseId, // 教学计划课程ID
        examPaperId: pager.examPaperId, // 试卷ID
        isFinish: param.isFinish,
        isFace: pager.examIsFacial, // 是否人脸审核
        facePass: pager.facePass, // 人脸审核是否通过(1-待审核，2-未通过，3-已通过)
        endTime: getcurrentTime(), // 交卷时间
        ip: '',
        deptId: store.state.user.userInfo.deptId, // 部门id
        longTime: longTime,
        verifyId: pager.faceId
      }

      obj.studentExamPaper = { ...state.examDetailJson, termId: pager.termId, ...obj } // 试卷内容

      // 调用接口
      return new Promise((resolve, reject) => {
        updateStudentRecord(obj)
          .then(res => {
            store.dispatch('setExamTimer', 0)
            commit('SET_EXAM_PAGER', {})
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 补考交卷
    finishAgainPager({ state, commit }, param) {
      const pager = state.examPagerData
      // 考试开始时间
      const startTime = pager.startTime * 1
      // 考试用时
      const longTime =
        calcDate(startTime, new Date().valueOf()).minutes > pager.examAnswerDuration * 1
          ? pager.examAnswerDuration
          : calcDate(startTime, new Date().valueOf()).minutes
      // 参数
      const obj = {
        hasSubjective: pager.hasSubjective, // 是否含有主观题 (0-否，1-是)
        studentId: window.localStorage.getItem('user_id'), // 学生id
        againExamId: pager.activityExamId, // 考试活动ID
        activityExamId: pager.activityExamId, // 考试活动ID
        teachingCourseId: pager.teachingCourseId, // 教学计划课程ID
        examPaperId: pager.examPaperId, // 试卷ID
        isFinish: param.isFinish,
        isFace: pager.examIsFacial, // 是否人脸审核
        facePass: pager.facePass, // 人脸审核是否通过(1-待审核，2-未通过，3-已通过)
        endTime: getcurrentTime(), // 交卷时间
        ip: '',
        deptId: store.state.user.userInfo.deptId, // 部门id
        longTime: longTime,
        verifyId: pager.faceId
      }
      obj.studentExamPaper = { ...state.examDetailJson, termId: pager.termId, ...obj } // 试卷内容全部

      // 调用接口
      return new Promise((resolve, reject) => {
        updateStudentAgainRecord(obj)
          .then(res => {
            commit('SET_EXAM_PAGER', {})
            store.dispatch('setExamTimer', 0)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 作业交卷
    finishTaskPager({ state, commit }, param) {
      const pager = state.examPagerData
      // 考试开始时间
      const startTime = pager.startTime * 1
      // 考试用时
      const longTime =
        calcDate(startTime, new Date().valueOf()).minutes > pager.examAnswerDuration * 1
          ? pager.examAnswerDuration
          : calcDate(startTime, new Date().valueOf()).minutes
      // 参数
      const obj = {
        hasSubjective: pager.hasSubjective, // 是否含有主观题 (0-否，1-是)
        studentId: window.localStorage.getItem('user_id'), // 学生id
        activityTaskId: pager.activityExamId, // 考试活动ID
        activityExamId: pager.activityExamId, // 考试活动ID
        teachingCourseId: pager.teachingCourseId, // 教学计划课程ID
        examPaperId: pager.examPaperId, // 试卷ID
        isFinish: param.isFinish,
        endTime: getcurrentTime(), // 交卷时间
        ip: '',
        deptId: store.state.user.userInfo.deptId, // 部门id
        longTime: longTime
      }

      obj.studentExamPaper = { ...state.examDetailJson, termId: pager.termId, ...obj } // 试卷内容

      // 调用接口
      return new Promise((resolve, reject) => {
        updateStudentPaperTaskExamSubmit(obj)
          .then(res => {
            commit('SET_EXAM_PAGER', {})
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },

  mutations: {
    SET_EXAM_PAGER: (state, examPagerData) => {
      state.examPagerData = examPagerData
      setStore({
        name: 'examPagerData',
        content: state.examPagerData,
        type: 'session'
      })
    },
    SET_EXAM_DETAIL: (state, examDetailJson) => {
      state.examDetailJson = examDetailJson
      setStore({
        name: 'examDetailJson',
        content: state.examDetailJson,
        type: 'session'
      })
    }
  }
}
export default exam
