import request from '@/api/axios'

// 新增考试记录
export const insertStudentExamRecord = (data, signal) => {
  return request({
    url: '/student/studentExamRecord/insertStudentExamRecord',
    method: 'post',
    data: data,
    signal: signal
  })
}
// 新增补考记录
export const insertStudentAgainExamRecord = (data, signal) => {
  return request({
    url: '/student/studentAgainExamRecord/insertStudentAgainExamRecord',
    method: 'post',
    data: data,
    signal: signal
  })
}

// 新增作业记录
export const insertStudentTaskRecord = (data, signal) => {
  return request({
    url: '/student/studentTaskRecord/insertStudentTaskRecord',
    method: 'post',
    data: data,
    signal: signal
  })
}

// 考试交卷
export const updateStudentRecord = data => {
  return request({
    url: '/student/studentPaper/updateStudentPaperExamSubmit',
    method: 'post',
    data: data
  })
}

// 补考交卷
export const updateStudentAgainRecord = data => {
  return request({
    url: '/student/studentPaper/updateStudentPaperAgainExamSubmit',
    method: 'post',
    data: data
  })
}

// 作业交卷
export const updateStudentPaperTaskExamSubmit = data => {
  return request({
    url: '/student/studentPaper/updateStudentPaperTaskExamSubmit',
    method: 'post',
    data: data
  })
}

// 提交作业
export const insertActivityTask = data => {
  return request({
    url: '/student/examinationCenter/insertActivityTask',
    method: 'post',
    data: data
  })
}

// 定时任务
export const testSet = () => {
  return request({
    url: '/student/studentPaper/getStudentEaxmScheduledTasksABC',
    method: 'get'
  })
}

// 获取新建考试活动任务id
export const getExamId = data => {
  return request({
    url: '/student/studentPaper/updateStudentVerify',
    method: 'post',
    data: data
  })
}

// 获取人脸识别审批状态最新
export const getFaceIdNew = data => {
  return request({
    url: '/student/studentPaper/selectStudentVerify',
    method: 'post',
    data: data
  })
}

// 补考交卷
export const updateStudentUnifyExamSubmit = data => {
  return request({
    url: '/student/schoolUnifyExam/updateStudentUnifyExamSubmit',
    method: 'post',
    data: data
  })
}

// 补考交卷
export const getStudentRedisId = data => {
  return request({
    url: '/student/studentPaper/getStudentRedisId',
    method: 'post',
    data: data
  })
}
