/*
 * @Description:: 亿学堂学生端
 * @Version: 1.0
 * @Author: lidandan
 * @Date: 2023-09-26 09:35:50
 * @LastEditors: lidandan
 * @LastEditTime: 2023-09-28 15:10:02
 * @FilePath: \zyjn\src\api\menu.js
 */
import request from "./request";

// 获取路由
export const getRouters = () => {
  return request({
    url: "/system/menu/getRouters",
    method: "get",
  });
};
