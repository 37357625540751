/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2023-02-03 09:53:28
 * @LastEditors: 小白
 * @LastEditTime: 2023-02-03 11:20:56
 * @FilePath: \yxb3-demo-new\src\const\website.js
 */
export default {
  key: "zyjn-student", // 配置主键,目前用于存储
};
