<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-08-24 10:51:05
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-04 09:50:29
 * @FilePath: /zyjn_student_pc/src/App.vue
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    const detectDeviceType = () => (/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? '1' : '2')
    if (detectDeviceType() === '1') {
      this.$router.push('/login?show=true')
    }
  }
}
</script>

<style lang="scss">
@import url('./assets/font/iconfont.css');
body {
  margin: 0;
  padding: 0;
  font-size: 15px !important;
}
#nprogress .bar {
  background: #ffffff !important;
}
</style>
