/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2022-10-18 14:05:31
 * @LastEditors: wengchen
 * @LastEditTime: 2023-11-22 17:43:19
 * @FilePath: \yzjn_student_pc\src\store\modules\user.js
 */
import { login, loginPhone, logout, getInfo, getStuInfoByIT } from '@/api/login'
import { setStore, getStore, clearStore } from '@/util/store.js'
import store from '@/layouts/store'
import { HTTP_URI } from '@/api/api'

const userInfo = getStore({ name: 'userInfo' }) || {}
const state = {
  // 用户头像
  avatar: userInfo.avatar || '',
  // 用户token
  token: getStore({ name: 'token' }) || '',
  // 用户角色
  roles: [],
  // 用户名称
  userName: userInfo.nickName || '',
  // 用户权限
  permissions: [],
  // 用户机构id
  tenantInfo: {},
  // 用户机构code
  tenantCode: {},
  // 学生信息
  studentInfo: {},
  // 头像
  avatarImg: '',
  // 是否实名
  isRealName: ''
}

const getters = {
  getAvatar(state) {
    return state.avatar || require('@/assets/img_avatar_example.gif')
  },
  getToken(state) {
    return state.token
  },
  getPermissions(state) {
    return state.permissions
  },
  getRoles(state) {
    return state.roles
  },
  getNickName(state) {
    return state.userName
  }
}

const actions = {
  // 登录
  handleLogin({ commit }, userInfo) {
    const username = userInfo.username.trim()
    const password = userInfo.password
    const code = userInfo.code
    const uuid = userInfo.uuid
    const loginType = '1'
    return new Promise((resolve, reject) => {
      login(username, password, code, uuid, loginType)
        .then(res => {
          // 存储token
          commit('SET_TOKEN', res.data.access_token)
          resolve(res.data.tenantId)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // 手机号登录存储token
  phoneLoginStore({ commit }, token) {
    commit('SET_TOKEN', token)
  },

  // 获取用户信息
  GetInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then(res => {
          commit('SET_USER_INFO', res)
          if (res.roles && res.roles.length > 0) {
            // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', res.roles)
            commit('SET_PERMISSIONS', res.permissions)
          } else {
            commit('SET_PERMISSIONS', ['ROLE_DEFAULT'])
          }
          resolve(res)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },
  // 获取学生信息
  GetStuInfoByIT({ commit, state }, param) {
    return new Promise((resolve, reject) => {
      // getStuInfoByIT(param)
      //   .then(res => {
      commit('SET_STUDENT_INFO', res.data)
      resolve(res.data)
      // })
      // .catch(error => {
      //   console.log(error)
      //   reject(error)
      // })
    })
  },
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      // logout(state.token)
      logout({})
        .then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', '')
          commit('SET_PERMISSIONS', '')
          store.state.permissionRoutes = []
          store.state.visitedView = []
          store.state.cachedView = []
          clearStore()
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  // 存储token
  SET_TOKEN(state, token) {
    state.token = token
    setStore({
      name: 'token',
      content: token
    })
  },
  SET_ROLES(state, roles) {
    state.roles = roles
  },
  SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions
  },
  // 存储用户信息
  SET_USER_INFO(state, res) {
    state.avatar = res.user.avatar || ''

    setStore({
      name: 'userInfo',
      content: { ...res.user, tenantId: getStore({ name: 'tenantInfo' }) }
    })
  },
  // 设置用户选择机构信息
  SET_TENANT_ID(state, tenant) {
    state.tenantInfo = tenant
    setStore({
      name: 'tenantInfo',
      content: tenant
    })
  },
  // 设置用户选择机构信息
  SET_TENANT_CODE(state, tenantCode) {
    state.tenantCode = tenantCode
    setStore({
      name: 'tenantCode',
      content: tenantCode
    })
  },
  // 存储学生信息
  SET_STUDENT_INFO(state, studentInfo) {
    console.log(getStore({ name: 'tenantInfo' }), 'getStore({name:tenantInfo})')
    state.studentInfo = studentInfo
    state.nickName = studentInfo.XM ? studentInfo.XM : ''
    state.avatarImg = studentInfo.TX ? HTTP_URI.IMGURL + studentInfo.TX : ''
    state.isRealName = studentInfo.SM
    setStore({
      name: 'studentInfo',
      content: { ...studentInfo, tenantId: getStore({ name: 'tenantInfo' }) }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
