<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-08-24 10:55:32
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-06-07 15:41:29
 * @FilePath: /yxb3_student_pc/src/components/layout/mainView.vue
-->
<template>
  <div class="content_box">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
  .content_box{
    width: 100%;
    height:100%;
  }
</style>

