/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-08-24 10:51:05
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-02-19 08:43:00
 * @FilePath: /stu_exam/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import mainView from '../components/layout/mainView.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => err)
}
export const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录' },
    component: () => import('../page/login/login.vue')
  }, // learning center
  {
    path: '/',
    component: mainView,
    children: [
      {
        path: '/',
        meta: { title: '首页', icon: 'icon-a-Group1000007321' },
        name: 'home',
        hidden: false,
        component: () => import('../views/home/index.vue')
      }
    ]
  },
  {
    path: '/examHome',
    name:"examHome",
    meta:{
      title:"考试中心"
    },
    component: ()=>import('../views/examHome/index.vue'),
  },
  {
    path: '/exam',
    name:"exam",
    meta:{
      title:"考试中心"
    },
    component: ()=>import('../views/exam/index.vue'),
  },
  {
    path: '/handleExam',
    name:"handleExam",
    meta:{
      title:"交卷"
    },
    component: ()=>import('../views/jiaojuan/index.vue'),
  },
  {
    path: '/countDown',
    component:mainView,
    children:[
      {
        path: '/countDown',
        name:"countDown",
        meta:{
          title:"考试倒计时"
        },
        component: ()=>import('../views/countDown/index.vue'),
      }
    ]
  }
]

// 防止连续点击多次路由报错
const routerPush = VueRouter.prototype.push
const routerReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err)
}
// replace
VueRouter.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch((err) => err)
}

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = []

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default new VueRouter({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes
})
