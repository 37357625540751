/*
 * @Description:: 亿学堂学生端
 * @Version: 1.0
 * @Author: lidandan
 * @Date: 2023-04-07 17:52:20
 * @LastEditors: lidandan
 * @LastEditTime: 2023-06-06 13:53:57
 * @FilePath: \yxb3_student_pc\src\util\date.js
 */
export const calcDate = (date1, date2) => {
  var date3 = date2 - date1

  var days = Math.floor(date3 / (24 * 3600 * 1000))

  var leave1 = date3 % (24 * 3600 * 1000) // 计算天数后剩余的毫秒数
  var hours = Math.floor(leave1 / (3600 * 1000))

  var leave2 = leave1 % (3600 * 1000) // 计算小时数后剩余的毫秒数
  var minutes = Math.ceil(leave2 / (60 * 1000))

  var leave3 = leave2 % (60 * 1000) // 计算分钟数后剩余的毫秒数
  var seconds = Math.round(date3 / 1000)
  return {
    leave1,
    leave2,
    leave3,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  }
}

/**
 * 日期格式化
 */
export function dateFormat(date) {
  let format = 'yyyy-MM-dd hh:mm:ss'
  if (date !== 'Invalid Date') {
    var o = {
      'M+': date.getMonth() + 1, // month
      'd+': date.getDate(), // day
      'h+': date.getHours(), // hour
      'm+': date.getMinutes(), // minute
      's+': date.getSeconds(), // second
      'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
      S: date.getMilliseconds() // millisecond
    }
    if (/(y+)/.test(format)) {
      format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
      }
    }
    return format
  }
  return ''
}

/**
 * 获取当前年月日
 */

export function getcurrentTime() {
  let date = new Date()
  let Y = date.getFullYear() //获取系统的年；
  let M = date.getMonth() + 1 //获取系统月份，由于月份是从0开始计算，所以要加1
  let D = date.getDate() //获取系统日
  let H = date.getHours() //获取系统时间
  let m = date.getMinutes() > 10 ? date.getMinutes() : '0' + date.getMinutes() //分
  let s = date.getSeconds() //秒
  M = M < 10 ? '0' + M : M
  D = D < 10 ? '0' + D : D
  H = H < 10 ? '0' + H : H
  s = s < 10 ? '0' + s : s
  return Y + '-' + M + '-' + D + ' ' + H + ':' + m + ':' + s
  // return Y + '年' + M + '月' + D + '日' + ' ' + H + ':' + m + ':' + s
}
