/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: GL
 * @Date: 2023-10-13 15:14:15
 * @LastEditors: GL
 * @LastEditTime: 2023-10-14 17:25:43
 * @FilePath: \zyjn\src\api\wangsu\index.js
 */
import request from "../request";
// 平台题库列表
const baseUrl = "/pinstitution";
export function wangSu(data, tag) {
  return request({
    url: tag ? "/ag/ws/getToken" : baseUrl + "/ws/getToken",
    method: "post",
    data: data
  });
}
// 通过本地图片路径上传文件
export function urlPost(data) {
  return request({
    url: baseUrl + "/ws/SliceUpload",
    method: "post",
    data: data,
    headers: { "Content-Type": "multipart/form-data" } // 设置请求头为文件流
  });
}
